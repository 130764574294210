<template>
    <div>
        <navbar :items="navItems"/>
        <data-table :dataSource="historiques" :headers="headers"/>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import Navbar from '../../components/navBar.vue'
import DataTable from '../../components/dataTable/local.vue'
import {TEXT_TYPE, NUMERIC_TYPE, DATE_TYPE} from '../../components/dataTable/dataType'
export default {
components: {DataTable, Navbar},
    data(){
        return {
            navItems: [
                {name: 'Historique'}
            ]
        }
    },
    methods: {

    },
    computed: {
        ...mapGetters({
            historiques: 'host/historiques',
        }),
        headers(){
            return [
                {label: 'Volume (ml)', name: 'volume', type: NUMERIC_TYPE, sortable: true},
                {label: 'Badge', name: 'badge', type: TEXT_TYPE, sortable: true},
                {label: 'Date', name: 'date', type: DATE_TYPE, sortable: true},
            ]
        }
    }
}
</script>